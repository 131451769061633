<template>
	<div class="inner">
		<img src="../assets/about-banner.png" width="100%" alt="" />
		<div class="container">
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">关于我们</div>
				<div class="fl ft24 bl9 ml12 mt12"> / About us</div>
			</div>
			<el-row :gutter="30">
				<el-col :span="11">
					<img src="../assets/about-img.png" width="100%" />
				</el-col>
				<el-col :span="13">
					<div class="ft20 pt30 pb30">{{companyInfo.name}}</div>
					<div class="ft16 tj l18">
						<div style="z-index: 2;">
							{{companyInfo.companyDesc}}
							<span @click="$router.push('/about-details')" class="bl9 fr ft16 pt14 hover">
								查看更多
								<i class="el-icon-arrow-right"></i>
							</span>
						</div>
						<div class="bg-text" style="right: 0;left: auto;">关于我们</div>
					</div>
				</el-col>
			</el-row>
			<div class="mt30 mb30">
				<el-row>
					<el-col :span="6">
						<div class="mt50 mb50 tc el-border-right">
							<div class="ft40 bold bl6">
								1600+
							</div>
							<div class="pt30 bl6 ft16">
								客户
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="mt50 mb50 tc el-border-right">
							<div class="ft40 bold bl6">
								300+
							</div>
							<div class="pt30 bl6 ft16">
								车型
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="mt50 mb50 tc el-border-right">
							<div class="ft40 bold bl6">
								300+
							</div>
							<div class="pt30 bl6 ft16">
								团队
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="mt50 mb50 tc">
							<div class="ft40 bold bl6">
								300+
							</div>
							<div class="pt30 bl6 ft16">
								销售网络
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">企业文化</div>
				<div class="fl ft24 bl9 ml12 mt12"> / Enterprise Culture</div>
			</div>
			<el-row :gutter="15">
				<el-col :span="6">
					<div class="cut-img blf ft40 tc l16">
						<img src="../assets/cut-3.png" width="100%">
						<div class="blf">
							<div class="ft20 bold pt50">企业宗旨</div>
							<div class="ft16 pt20">顾客满意，服务至上</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="cut-img blf ft40 tc l16">
						<img src="../assets/cut-1.png" width="100%">
						<div class="blf">
							<div class="ft20 bold pt50">企业作风</div>
							<div class="ft16 pt20">雷厉风行，纪律严明</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="cut-img blf ft40 tc l16">
						<img src="../assets/cut-2.png" width="100%">
						<div class="blf">
							<div class="ft20 bold pt50">企业精神</div>
							<div class="ft16 pt20 l20">求实创新，敢为人先<br />追求卓越，敬业奉献</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="cut-img blf ft40 tc l16">
						<img src="../assets/cut-3.png" width="100%">
						<div class="blf">
							<div class="ft20 bold pt50">企业责任</div>
							<div class="ft16 pt20 l20">对社会负责，为客户、股<br />
								东、员工和社会创造价值</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">荣誉资质</div>
				<div class="fl ft24 bl9 ml12 mt12"> / Honorary Certificate</div>
			</div>
			<el-row :gutter="30">
				<el-col :span="12">
					<div class="ft16 l18 mt40 mb50">
						<div v-for="(item, index) in honorList">{{index + 1}}.{{item.name}}</div>
						<div class="bg-text">荣誉资质</div>
					</div>
				</el-col>
				<el-col :span="12">
					<img src="../assets/zizhi-img.png" class="mt40 mb50" width="100%">
				</el-col>
			</el-row>
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">团队风采</div>
				<div class="fl ft24 bl9 ml12 mt12"> / Team's Mien</div>
			</div>
			<div class="introinfo-nav tc ft24 el-border-bottom pb30 mt40 mb30">
				<el-row>
					<el-col :span="12">
						<div :class="current==0?'red':''" @click="changeNav(0)" class="el-border-right hover">图片</div>
					</el-col>
					<el-col :span="12">
						<div :class="current==1?'red':''" class="hover" @click="changeNav(1)">视频</div>
					</el-col>
				</el-row>
			</div>
			<el-row :gutter="15" v-if="current==0">
				<el-col :span="8" v-for="(item,index) in teamImgs" :key="index">
					<div class="video-list mb16">
						<div class="video-list-img">
							<el-image :preview-src-list="srcList" :src="item.mainPic" fit="cover"
								style="width: 100%;height: 100%;" @load="handlePreview(item.mainPic)"/>
						</div>
						<div class="ft18 tc pb20 pt12">{{item.name}}</div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="15" v-if="current==1">
				<el-col :span="8" v-for="(item,index) in teamVideos" :key="index">
					<div class="video-list mb30">
						<div class="video-list-img">
							<video  width="100%"
								:src="item.mainVideo"
								controls="controls" height="100%" @play="handlePlayVideo(index)"></video>
						</div>
						<div class="ft18 tc pb20 pt12">{{item.name}}</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import { getAboutUs, getHonor, getTeam } from '@/api/index.js'
	export default {
		data() {
			return {
				current: 0,
				srcList: [],
				companyInfo:{
					id: '',
					name: '',
					companyDesc: ''
				},
				honorList: [],
				videoElement:[],

				teamList: [],
				teamImgs: [],
				teamVideos: []
			}
		},
		created(){
			this.getAboutUsInfo()
			this.getHonor()
			this.getTeamInfo()
		},
		mounted(){
			this.videoElement = document.getElementsByTagName('video')
		},
		methods: {
			//导航切换
			changeNav(index) {
				this.current = index
			},
			/*** 关于我们 **/
			getAboutUsInfo(){
				getAboutUs().then(res => {
					if(res.code === 200){
						this.companyInfo = {
							id: res.data.id,
							name: res.data.name,
							companyDesc: res.data.companyDesc
						}
					}
				})
			},

			/**
			 * 视频播放
			 * @param index
			 */
			handlePlayVideo(index){
				const videoElement = this.videoElement
				if (videoElement && videoElement.length > 0) {
					for (let i = 0; i < videoElement.length; i++) {
						if (i === index) {
							this.videoElement[i].play()
						} else {
							this.videoElement[i].pause()
						}
					}
				}
			},
			/**
			 * 荣誉资质
			 */
			getHonor(){
				getHonor().then(res => {
					if(res.code === 200){
						this.honorList = res.data;
					}
				})
			},
			/** 预览图片 **/
			handlePreview(mainPic){
				this.srcList.push(mainPic)
			},
			/** 获取团队风采 **/
			getTeamInfo(){
				getTeam().then(res => {
					if(res.code === 200){
						this.teamList = res.data;
						let temTeamImgs = []
						this.teamList.forEach(item=>{
							if(item.mainPic != ''){
								let imgs = item.mainPic.split(',')
								temTeamImgs.push({
									id: item.id,
									name: item.name,
									mainPic: imgs[0]
								})
							}
						})
						this.teamImgs = temTeamImgs;

						let tempVideo = []
						this.teamList.forEach(item=>{
							if(item.mainVideo != ''){
								let temVideo = item.mainVideo.split(',')
								tempVideo = tempVideo.concat(temVideo)
							}
						})
						if(tempVideo && tempVideo.length > 0){
							let temTeamVideos = []
							tempVideo.forEach(item => {
								let video = item.substr(item.lastIndexOf('/') +1)
								video = video.substr(0, video.lastIndexOf('.'))
								temTeamVideos.push({
									name: video,
									mainVideo: item
								})
							})
							this.teamVideos = temTeamVideos
						}
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.tools-list:hover {
		color: #b4232f;
		transform: translateX(4px);
	}

	.container {
		margin-bottom: 100px;
	}

	.cut-img {
		position: relative;
		height: 190px;
		margin-bottom: 30px;
	}

	.cut-img img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.cut-img .blf {
		position: relative;
		z-index: 2;
	}

	.bg-text {
		font-size: 107px;
		letter-spacing: 0px;
		color: #e1e1e1;
		opacity: 0.3;
		position: absolute;
		left: 0;
		bottom: -70px;
		z-index: 1;
	}

	.video-list-img {
		border-radius: 10px;
		overflow: hidden;
		height: 250px;
	}
</style>
